import {JsonObject, JsonProperty} from "json2typescript";
import Auditing from "@/models/Auditing";

@JsonObject("Brand")
export default class Brand extends Auditing {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("description", String, true)
    description?: string = undefined
    @JsonProperty("code", String, true)
    code?: string = undefined

}
