import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("Rate")
export default class Rate {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", Number, true)
    connectorId?: number = undefined
    @JsonProperty("price", String, true)
    price?: string = undefined

}
